// https://stackoverflow.com/questions/18773778/create-array-of-unique-objects-by-property
const getUniqueBy = (arr, prop) => {
    const set = new Set();
    return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
};


const getNewsFeed = () => {
    let url = "https://russell-article-saver.fly.dev/getArticles"
    const res = fetch(url, {
        method: 'GET', 
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    return res.then((out) => {
        let body = out.json()
        return body.then((data) => {
            return getUniqueBy(data.articles, 'url')
        })
    });
}

export default getNewsFeed