import getBlogPosts from "../shared/functions/getBlogPosts"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import gfm from 'remark-gfm'


const BlogPostDetail = ({ title, date, text }) => {
    return (
        <div style={{ marginLeft: '50px', maxWidth: '550px' }}>
            <p>{date.substring(0, 10)}</p>
            <p style={{ fontSize: '1.8em' }}><strong>{title}</strong></p>
            <ReactMarkdown children={text} remarkPlugins={[gfm]} />
        </div>
    )
}

const BlogPost = () => {
    const [posts, setPostsData] = useState([])
    useEffect(() => {
        const postsData = getBlogPosts()
        postsData.then((x) => {
            setPostsData(x)
        })
    }, [])
    const { urlWord } = useParams()
    let post = posts.find(post => post.urlWord === urlWord)

    if (!post) {
        return (
            <div style={{ marginLeft: '40px' }}>
                <p>That blog post doesn't exist!</p>
                <p><a href='/blog'>Back to blog</a></p>
            </div>
        )
    }

    return (
        <div>
            <h1 style={{ marginLeft: '20px' }}>Blog</h1>

            <BlogPostDetail title={post.title} date={post.date} text={post.text} />
            <br />
            <br />
            <br />
            <br />
            <div style={{ marginLeft: "20px" }}>
                <a href='/blog'>Back to posts</a>
            </div>
        </div>
    )

}

export default BlogPost