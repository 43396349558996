const BlockLink = ({ body, link, href }) => {
    return (
        <blockquote>
            {body} 
            <br/>
            <a href={href} rel="noreferrer" target='_blank'>{link}{link ? ' →' : ''}</a>
        </blockquote>
    )
}

export default BlockLink