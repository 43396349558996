import BlockLink from "../shared/components/BlockLink"

const Home = () => {
    return (
        <div>
            <BlockLink link="DM me on Twitter." href="https://twitter.com/russellromney" body="Based in New York City. Want to get in touch?" />

            <blockquote>
                Currently the founding engineer at <a rel="noreferrer" target="_blank" href="https://popchew.com">Popchew</a>,
                building a consumer brand around virtual restaurants for creators.
                Previously worked in data science, data engineering, and ML engineering, most recently at <a rel="noreferrer" target="_blank" href="https://fetch.com">Fetch Rewards</a>.
            </blockquote>

            <blockquote>
                I run <a href="https://thunklab.com" target="_blank" rel="noreferrer">thunk.lab</a>, working with a killer team to do consulting accross industries. 
                We build full-stack projects, technical frontend projects, product MVPs, Python/cloud backends, cloud architecture, 
                ML eng/ops, data warehouses, and analytics stacks. 
                
                I've previously done a lot of work on Plotly Dash-based platforms, setting up the core system, 
                authentication, and access control components on which to build your business.
                
                I'd love to hear about your project!
            </blockquote>

            <p><strong>seeking</strong></p>
            <blockquote>
                books, action sports, karaoke, family, dinner parties, travel, adventures, live music
            </blockquote>

            <p><strong>channels</strong></p>
            <BlockLink link="github" href="https://github.com/russellromney" />
            <BlockLink link="twitter" href="https://twitter.com/RussellRomney" />
            <BlockLink link="linkedin" href="https://linkedin.com/in/russellromney" />
            <BlockLink link="instagram" href="https://instagram.com/russellthehippo" />
            <BlockLink link="goodreads" href="https://www.goodreads.com/user/show/25320525-russell-romney" />

            <p><strong>signals</strong></p>
            <BlockLink body="80% creating new experiences, 20% appreciating past experiences." />
            <BlockLink body="Each day is short - take some time to enjoy yourself." link="100 Blocks a Day" href="https://waitbutwhy.com/2016/10/100-blocks-day.html" />
            <BlockLink body="Life is short - family matters." link="Your Life in Weeks" href="https://waitbutwhy.com/2014/05/life-weeks.html" />
            <BlockLink body="Most rules are more like guidelines, anyway." href="https://www.youtube.com/watch?v=k9ojK9Q_ARE" link="Youtube" />
            <BlockLink body={`“Change takes time, and time is all it takes” is as true for heartbreak as it is for failure, rejection, shock, pain, career transitions.`} href="https://www.youtube.com/watch?v=9mbp0DugfCA" link="Youtube" />

            <p><strong>projects</strong></p>
            <BlockLink link="brain-plasma" href="https://github.com/russellromney/brain-plasma" body="shared inter-thread memory namespace for Python webapps" />
            <BlockLink body="batteries-included access control + authorization for Dash apps" link="dash-access" href="https://github.com/russellromney/dash-access" />
            <BlockLink body="batteries-included authentication for Dash apps" link="dash-auth-flow" href="https://github.com/russellromney/dash-auth-flow" />
            <BlockLink body="tiny python dataframes with pandas-compatible API in AWS Lambda" link="duffel" href="https://github.com/russellromney/duffel" />
            <BlockLink body="custom web-based interactive presentation slides in Python" link="dash-slides" href="https://github.com/russellromney/dash-slides" />
            {/* <BlockLink body="Dash app Docker image running on AWS ECS" link="docker-dash" href="https://github.com/russellromney/docker-dash" /> */}

            <p><strong>tools</strong></p>
            <BlockLink body="serverless APIs" />
            <BlockLink body="cloud microservices" />
            <BlockLink body="full-stack data" />
            <BlockLink body="python" />
            <BlockLink body="AWS/GCP" />
            <BlockLink body=<a rel="noreferrer" target="_blank" href="https://dash.plotly.com">Plotly Dash</a> />

        </div>
    )
}

export default Home