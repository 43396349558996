// https://stackoverflow.com/questions/18773778/create-array-of-unique-objects-by-property
const getUniqueBy = (arr, prop) => {
    const set = new Set();
    return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
};


const getNewsQueue = () => {
    let url = "https://russell-article-saver.fly.dev/getQueueArticles"
    const res = fetch(url, {
        method: 'GET', mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    return res.then((out) => {
        let body = out.json()
        return body.then((data) => {
            return getUniqueBy(data.articles, 'url')
        })
    });
}

const getNewsQueueInfo = () => {
    let url = "https://russell-article-saver.fly.dev/getQueueArticlesInfo"
    const res = fetch(url, {
        method: 'GET', mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    return res.then((out) => {
        return out.json()
    });
}


export {getNewsQueue,getNewsQueueInfo}