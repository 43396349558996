import { useEffect, useState } from "react";
import getJumpPhotos from "../functions/getJumpPhotos";

const imgHeight = "125";

const JumpPhotos = () => {
  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    const photosData = getJumpPhotos();
    photosData.then((x) => {
      setPhotos(x);
    });
  }, []);

  return (
    <div>
      <ul id="nav" className="nav-ul" key="jumpPhotosNav">
        {photos.map((x) => (
          <li key={"jumpPhotosNav-" + x.title}>
            <img
              src={x.file.url + `?h=125`}
              alt={x.title}
              key={x.title}
              height={imgHeight}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default JumpPhotos;
