import getContentfulContentTypeEntries from "./contentful/contentful";

const CONTENT_TYPE = "blogPost";

const getBlogPosts = () => {
  const entries = getContentfulContentTypeEntries(CONTENT_TYPE);
  return entries.then((out) => {
    return out
      .filter((x) => x.sys.contentType.sys.id === CONTENT_TYPE)
      .map((x) => x.fields);
  });
};

export default getBlogPosts;
