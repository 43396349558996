import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Blog from "./routes/Blog";
import Articles from "./routes/Articles";
import BlogPost from "./routes/BlogPost";
import JumpPhotos from "./shared/components/JumpPhotos";
import NewsFeed from "./routes/NewsFeed";
import NewsQueue from "./routes/NewsQueue";
// import ThunkLabs from "./routes/ThunkLabs";
// import Projects from "./routes/Projects";

const App = () => {
  return (
    <div className="App">
      <Router>
        <div>
          <JumpPhotos />
          <ul id="nav" className="nav-ul">
            <li className="nav-li">
              <strong>Russell Romney</strong>
            </li>
            <li className="nav-li">
              <Link to="/">Home</Link>
            </li>
            {/* <li className="nav-li">
              <Link to="/blog">Blog</Link>
            </li> */}
            {/* <li className="nav-li">
              <Link to="/blog">Projects</Link>
            </li> */}
            <li className="nav-li">
              <Link to="/articles">Killer Reads</Link>
            </li>
            <li className="nav-li">
              <Link to="/feed">Reading Feed</Link>
            </li>
            <li className="nav-li">
              <Link to="/queue">Reading Queue</Link>
            </li>
            <li className="nav-li">
              <a href="https://thunklab.com" target="_blank" rel="noreferrer">thunk.lab</a>
              {/* <Link to="/thunklab">thunk.lab</Link> */}
            </li>
          </ul>

          {/* <JumpPhotos /> */}

          {/*
            A <Switch> looks through all its children <Route>
            elements and renders the first one whose path
            matches the current URL. Use a <Switch> any time
            you have multiple routes, but you want only one
            of them to render at a time
          */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:urlWord" element={<BlogPost />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/feed" element={<NewsFeed />} />
            <Route path="/queue" element={<NewsQueue />} />
            
            {/* <Route path='/thunklab' element={() => {
                window.location.replace('https://example.com/1234');
                return null;
            }}/> */}

            {/* <Route path="/thunklab" render={() => {
              console.log("doing something");
              window.open("https://www.google.com",'_blank');
            }} /> */}

            {/* <Route
              path="/thunklab"
              element={() => {
                window.open("https://thunklab.com", "_blank");
                return null;
              }}
            /> */}

            {/* <Route path="/projects" element={<Projects />} /> */}
          </Routes>

          <div style={{ fontSize: "12px", position: "relative", bottom: "0" }}>
            <br />
            <br />
            <br />
            <br />
            <p>Built with ❤️ in NYC.</p>
            {/* <p>Coded in React and hosted by Netlify</p>
            <p>Blog and photo content is hosted on Contentful</p>
            <p>Database runs serverless Postgres on Neon.tech</p>
            <p>Custom backend pieces are running in Flask on fly.io</p> */}
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
