const contentful = require('contentful')

const getContentFulClient = () => {
    const client = contentful.createClient({
        space: 'keaa560pjve3',
        environment: 'master', // defaults to 'master' if not set
        accessToken: 'cm2z_OM_iaRJLLiHDXd0GqyhfhyobKqlg58ADPiy94I'
    })
    return client
}
const getContentfulContentTypeEntries = ({ content_type }) => {
    const client = getContentFulClient()
    const out = client.getEntries({ content_type: content_type })
        .then((response) => {
            return response.items;
        })
    return out
}

export default getContentfulContentTypeEntries