import getContentfulContentTypeEntries from './contentful/contentful'


const CONTENT_TYPE = 'jumpPhoto'

const getJumpPhotos = () => {
    const entries = getContentfulContentTypeEntries(CONTENT_TYPE)
    return entries.then((out) => {
        const done = out
            .filter(x => x.sys.contentType.sys.id === CONTENT_TYPE)
            .map(x => x.fields.photo.fields)
        return done
    })

}

export default getJumpPhotos