import { useEffect, useState } from "react"
import BlockLink from "../shared/components/BlockLink"
import getBlogPosts from "../shared/functions/getBlogPosts"


const Posts = () => {
    const [posts, setPostsData] = useState([])

    useEffect(() => {
        const postsData = getBlogPosts()
        postsData.then((x) => {
            x.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });
            setPostsData(x)
        })
    }, [])

    return (
        <div>
            {
                posts.map(post =>
                (
                    <div key={post.urlWord}>
                        {/* <BlogPostPreview title={post.title} date={post.date} text={post.text} /> */}
                        <p key={post.urlWord}>
                            <a href={`/blog/${post.urlWord}`} key={post.urlWord}>
                                <i>[ {post.date.substring(0, 10)} ]</i> <strong>{post.title}</strong>
                            </a>
                        </p>
                        {/* <br /> */}
                        {/* <p><ReactMarkdown children={post.text} /></p> */}
                    </div>
                )
                )
            }
        </div>
    )
}

const Blog = () => {
    return (
        <div>
            <h1 style={{ marginLeft: '20px' }}>Blog</h1>
            <BlockLink
                body="My writing."
            />
            
            <hr style={{ marginLeft: '40px' }}/>

            <div style={{ marginLeft: '40px' }}>
                <Posts />
                <p>
                    <a href="https://www.strongtowns.org/journal/2018/6/7/the-missing-middle-of-biking" key="missingMiddleBiking" target="_blank" rel="noreferrer" >
                        <i>[ 2018-06-18 ]</i> <strong>The Missing Middle of Biking (in Strong Towns)</strong>
                    </a>
                </p>
            </div>
        </div>
    )
}

export default Blog